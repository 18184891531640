<template>
  <div class="card card-custom gutter-b">
    <div v-if="!isInitialLoading" class="card-body">
      <div class="d-flex">
        <div class="flex-grow-1">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center mr-3">
              <template v-if="reise">
                <a
                  class="d-flex align-items-center flex-wrap text-dark text-nowrap font-size-h5 font-weight-bold mr-3"
                  >{{ reise.reisekuerzel }}</a
                >
              </template>
              <div v-if="reise" class="d-flex flex-wrap my-2">
                <a class="d-flex align-items-center text-muted font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                  <i class="flaticon-earth-globe mr-1"></i>
                  {{ $t('REISEN.REGIONS.' + reise.region) }}
                </a>
                <a class="d-flex align-items-center text-muted font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                  <i class="flaticon-earth-globe mr-1"></i>
                  {{ $t('REISEN.COUNTRIES.' + reise.country) }}
                </a>
                <div
                  title="Priorität"
                  class="d-flex align-items-center text-muted font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                >
                  <i class="flaticon-exclamation mr-1"></i>
                  {{ convertPriorityFromConfig(reise.prioritaet) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-skeleton v-else class="m-2" height="76px" width="500px"></b-skeleton>
  </div>
</template>

<script>
import configPirority from '@/core/produkte/reisen/priority.config.json';

export default {
  components: {},
  props: {
    reise: Object,
    isInitialLoading: Boolean,
    isBusy: Boolean,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    convertPriorityFromConfig(priority) {
      return configPirority[priority].value;
    },
  },
};
</script>
