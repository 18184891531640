<template>
  <div>
    <portal to="subheader-left">
      <div class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        <h5 class="text-dark font-weight-bold my-5 mr-5">{{ reisekuerzel }}</h5>
        <div class="subheader-separator subheader-separator-ver mt-4 mb-2 mr-5 bg-gray-200"></div>
        <ul class="nav nav-light-primary nav-bold nav-pills py-2">
          <router-link
            v-allowedRoles="[Role.ADMIN, Role.PM, Role.DOM, Role.YIELD]"
            custom
            :to="{ name: 'reisen-edit-view', params: { reisekuerzel: reisekuerzel } }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li class="nav-item">
              <a
                class="nav-link px-xs-3 px-lg-4"
                :class="{ 'menu-item-active': isActive, active: $route.name === 'reisen-edit-view' }"
                :href="href"
                @click="navigate"
              >
                <span>Allgemeine Daten</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-allowedRoles="[Role.ADMIN, Role.DOM, Role.FOM]"
            custom
            :to="{ name: 'reisen-edit-passdatenkonfiguration-view', params: { reisekuerzel: reisekuerzel } }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li class="nav-item">
              <a
                class="nav-link px-xs-3 px-lg-4"
                :class="{
                  'menu-item-active': isActive,
                  active: $route.name === 'reisen-edit-passdatenkonfiguration-view',
                }"
                :href="href"
                @click="navigate"
              >
                <span>Passdaten-Konfiguration</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-allowedRoles="[Role.ADMIN, Role.PM, Role.YIELD]"
            custom
            :to="{ name: 'reisen-reisestammdaten-view', params: { reisekuerzel: reisekuerzel } }"
            v-slot="{ href, navigate, isActive }"
          >
            <li class="nav-item">
              <a
                class="nav-link px-xs-3 px-lg-4"
                :class="{
                  'menu-item-active': isActive,
                  active: $route.name === 'reisen-reisestammdaten-view',
                }"
                :href="href"
                @click="navigate"
              >
                <span>Reisestammdaten</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-allowedRoles="[Role.ADMIN, Role.PM]"
            custom
            :to="{ name: 'reisen-saisonpreise-view', params: { reisekuerzel: reisekuerzel } }"
            v-slot="{ href, navigate, isActive }"
          >
            <li class="nav-item">
              <a
                class="nav-link px-xs-3 px-lg-4"
                :class="{
                  'menu-item-active': isActive,
                  active: $route.name === 'reisen-saisonpreise-view',
                }"
                :href="href"
                @click="navigate"
              >
                <span>TH / VK Max</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </portal>
  </div>
</template>

<script>
export default {
  name: 'reisen-view-nav',
  props: {
    reisekuerzel: String,
  },
};
</script>
