var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('portal',{attrs:{"to":"subheader-left"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"},[_c('h5',{staticClass:"text-dark font-weight-bold my-5 mr-5"},[_vm._v(_vm._s(_vm.reisekuerzel))]),_c('div',{staticClass:"subheader-separator subheader-separator-ver mt-4 mb-2 mr-5 bg-gray-200"}),_c('ul',{staticClass:"nav nav-light-primary nav-bold nav-pills py-2"},[_c('router-link',{directives:[{name:"allowedRoles",rawName:"v-allowedRoles",value:([_vm.Role.ADMIN, _vm.Role.PM, _vm.Role.DOM, _vm.Role.YIELD]),expression:"[Role.ADMIN, Role.PM, Role.DOM, Role.YIELD]"}],attrs:{"custom":"","to":{ name: 'reisen-edit-view', params: { reisekuerzel: _vm.reisekuerzel } }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link px-xs-3 px-lg-4",class:{ 'menu-item-active': isActive, active: _vm.$route.name === 'reisen-edit-view' },attrs:{"href":href},on:{"click":navigate}},[_c('span',[_vm._v("Allgemeine Daten")])])])]}}])}),_c('router-link',{directives:[{name:"allowedRoles",rawName:"v-allowedRoles",value:([_vm.Role.ADMIN, _vm.Role.DOM, _vm.Role.FOM]),expression:"[Role.ADMIN, Role.DOM, Role.FOM]"}],attrs:{"custom":"","to":{ name: 'reisen-edit-passdatenkonfiguration-view', params: { reisekuerzel: _vm.reisekuerzel } }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link px-xs-3 px-lg-4",class:{
                'menu-item-active': isActive,
                active: _vm.$route.name === 'reisen-edit-passdatenkonfiguration-view',
              },attrs:{"href":href},on:{"click":navigate}},[_c('span',[_vm._v("Passdaten-Konfiguration")])])])]}}])}),_c('router-link',{directives:[{name:"allowedRoles",rawName:"v-allowedRoles",value:([_vm.Role.ADMIN, _vm.Role.PM, _vm.Role.YIELD]),expression:"[Role.ADMIN, Role.PM, Role.YIELD]"}],attrs:{"custom":"","to":{ name: 'reisen-reisestammdaten-view', params: { reisekuerzel: _vm.reisekuerzel } }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive }){return [_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link px-xs-3 px-lg-4",class:{
                'menu-item-active': isActive,
                active: _vm.$route.name === 'reisen-reisestammdaten-view',
              },attrs:{"href":href},on:{"click":navigate}},[_c('span',[_vm._v("Reisestammdaten")])])])]}}])}),_c('router-link',{directives:[{name:"allowedRoles",rawName:"v-allowedRoles",value:([_vm.Role.ADMIN, _vm.Role.PM]),expression:"[Role.ADMIN, Role.PM]"}],attrs:{"custom":"","to":{ name: 'reisen-saisonpreise-view', params: { reisekuerzel: _vm.reisekuerzel } }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive }){return [_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link px-xs-3 px-lg-4",class:{
                'menu-item-active': isActive,
                active: _vm.$route.name === 'reisen-saisonpreise-view',
              },attrs:{"href":href},on:{"click":navigate}},[_c('span',[_vm._v("TH / VK Max")])])])]}}])})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }